/* General styles for the section */
.general-blog {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 120px 0;
    background: #eff2f7;
  }
  
  /* container1 styles */
  .container1 {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    
   @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
    
  }

  .top1 {
    display: flex;
    flex-direction: row;
  }

 .blog-title {
    /* line-height: 55px; */
    font-size: 45px;
    color: #3B97D3;
    padding-bottom: 10px;
    padding-right: 12px;
    

    @media screen and (max-width: 1400px) {
      padding-right:50px;
    }


  }

   /* icon animation */
   @-webkit-keyframes rotate {
    100% {
      transform: rotate(1turn);
    } 
  }
  
  @keyframes rotate {
   100% {
      transform: rotate(1turn);
    }
  }
  
  
  .anim-border {
    position: relative;
    z-index: 0;
    width: 1px;
    height: 1px; /* Set the height to match the width for a perfect circle */
    border-radius: 50%; /* Make it a circle */
    overflow: hidden;
    padding: 2rem;
    border: 2px solid #eff2f7;
    float: right;
    margin-top: -10px;

    @media screen and (max-width: 1200px) {
      display: none;
    }
  }
  
  .anim-border::before {
    content: "";
    position: absolute;
    z-index: -2;
    left: -50%;
    top: -50%;
    width: 200%;
    height: 200%;
    background-repeat: repeat;
    background-size: 50% 50%, 50% 50%;
    background-position: 0 0, 100% 0, 100% 100%, 0 100%;
    background-image: linear-gradient(#eff2f7, #917af9 5%, #3B97D3 95%, #eff2f7);
    animation: rotate 4s linear infinite;
    border-radius: 50%; /* Make it a circle */
   
  }
  
  .anim-border::after {
    content: "";
    position: absolute;
    z-index: -1;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    /* background: white; */
    border-radius: 50%; /* Make it a circle */
  }
  
  .fa-icon {
    width: 100%;
    font-size: 40px;
    color:#eff2f7;
    margin-top: -22px;
    margin-left: -24px;
  } 

  .fa-icon1 {
    width: 100%;
    font-size: 40px;
    color:#eff2f7;
    margin-top: -23px;
    margin-left: -17px;
  } 

  .fa-icon2 {
    width: 100%;
    font-size: 40px;
    color:#eff2f7;
    margin-top: -22px;
    margin-left: -15px;
  } 

 .Short-Description {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 25px;
    padding-bottom: 20px;
  }

  .credits {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @media screen and (max-width: 700px) {
      display: flex;
      flex-direction:column;
      justify-content: center;
      align-items: center;
    }
  }

  .blog-img {
    height: 800px;
    width: 1000px;
    padding-bottom: 20px;

    @media screen and (max-width: 1400px) {
      width: 800px;
      height: 600px;
    }

    @media screen and (max-width: 1200px) {
      width: 650px;
      height: 600px;
    }

    @media screen and (max-width: 1000px) {
      width: 400px;
    }

    @media screen and (max-width: 700px) {
      width: 400px;
      height: 400px;
    }
  }

  .right-side {
    margin-top: -125px;

    @media screen and (max-width: 1400px) {
      margin-top: -100px;
   }
  }

  .writer-img {
    width: 325px; 
  
    @media screen and (max-width: 1400px) {
      width: 250px; 
   }

   @media screen and (max-width: 700px) {
    width: 325px; 
 }
  }

  .fa-check {
    color:#3B97D3;
    
  }

  .blog-date {
    padding-left: 11px;
  }

  .blog-dates {
    font-weight: 900;
    font-size: 20px;
    padding-bottom: 10px;

    @media screen and (max-width: 1400px) {
      font-size: 15px;
      padding-bottom: 5px;
   }

   @media screen and (max-width: 700px) {
    font-size: 20px;
    padding-bottom: 10px;
 }

  }

  /* blog-teilen animation */
  @-webkit-keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
    }
    @keyframes rotate {
    100% {
      transform: rotate(1turn);
    }
    }

    .anim-border1 h1 {
      width: 100%;
      padding-left: 20px;

      @media screen and (max-width: 1400px) {
        padding-left: 20px;
        font-size:17px;
      }

      @media screen and (max-width: 700px) {
        padding-left: 20px;
        font-size:20px;
      }
    }

    .anim-border1 {
      position: relative;
      z-index: 0;
      width: 142px;
      height: auto;
      border-radius: 10px;
      overflow: hidden;
      padding: 1rem;
      margin-left: 70px;
      margin-bottom: 10px;

      @media screen and (max-width: 1400px) {
        margin-bottom: 5px;
        margin-left: 35px;
        width: 130px;
     }

     @media screen and (max-width: 700px) {
      margin-bottom: 10px;
      margin-left: 70px;
      width: 142px;
   }
    }
    .anim-border1::before {
      content: "";
      position: absolute;
      z-index: -2;
      left: -50%;
      top: -50%;
      
      width: 200%;
      height: 200%;
      background-color: #3B97D3;
      background-repeat: no-repeat;
      background-size: 50% 50%, 50% 50%;
      background-position: 0 0, 100% 0, 100% 100%, 0 100%;
      background-image: linear-gradient( #2e00ff, #3B97D3 );
      -webkit-animation: rotate 7s linear infinite;
      animation: rotate 7s linear infinite;
    }
    .anim-border1::after {
    content: "";
    position: absolute;
    z-index: -1;
    left: 6px;
    top: 6px;
    width: calc(100% - 12px);
    height: calc(100% - 12px);
    background: #eff2f7;
    border-radius: 5px;
    } 
    
  
  .bottom-buttons {
    margin-bottom: 25px;    
} 

.link-button {
  text-decoration: none;
  color: inherit;
}

.more-new {
  color: #3b97d3;
  font-weight: 900;
  text-transform: uppercase;
  transition: all 0.3s ease;
  cursor: pointer;
  padding: 10px 10px;
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.25);
  background: none;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  font-size: 25px;
  margin-bottom: 10px;
  text-align: center;
  /* margin-right: 150px; */

  @media screen and (max-width: 1400px) {
    margin-bottom: 5px;
    font-size: 15px;
 }
  
  @media screen and (max-width: 700px) {
    margin-bottom: 10px;
    font-size: 25px;
  }
}

.bottom-buttons a.link-button:nth-child(1) .more-new {
  background-color: #0077B5;
  color: #eff2f7;
  border: 2px solid #eff2f7;
}

.bottom-buttons a.link-button:nth-child(1):hover .more-new {
  color: #0077B5;
  background-color: #eff2f7;
  border: 1px solid #0077B5;
  transition: transform 0.3s all;
  transform: scale(1.02);
}

.bottom-buttons a.link-button:nth-child(2) .more-new {
  background-color: #F600BF;
  color: #eff2f7;
  border: 1px solid #eff2f7;
}

.bottom-buttons a.link-button:nth-child(2):hover .more-new {
  color: #F600BF;
  background-color: #eff2f7;
  border: 1px solid #F600BF;
  transition: transform 0.3s all;
  transform: scale(1.02);
}

.bottom-buttons a.link-button:nth-child(3) .more-new {
  background-color: #4867AA;
  color: #eff2f7;
  border: 1px solid #eff2f7;
}

.bottom-buttons a.link-button:nth-child(3):hover .more-new {
  color: #4867AA;
  background-color: #eff2f7;
  border: 1px solid #4867AA;
  transition: transform 0.3s all;
  transform: scale(1.02);
}

.bottom-buttons a.link-button:nth-child(4) .more-new {
  background-color: #3B97D3;
  color: #eff2f7;
  border: 1px solid #eff2f7;
}

.bottom-buttons a.link-button:nth-child(4):hover .more-new {
  color: #3B97D3;
  background-color: #eff2f7;
  border: 1px solid #3B97D3;
  transition: transform 0.3s all;
  transform: scale(1.02);
}

.Long-Description {
  font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  font-size: 25px;
  padding-bottom: 20px;
  text-align: left;
  /* line-height: 25px; */
}

 
  