.container-testimonial{
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-family: consolas;
    gap: 50px;

    @media screen and (max-width: 1200px) {
        gap: 20px;
    }

    @media screen and (max-width: 768px) {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 0px;
    }
}

.container-testimonial .card{
    position: relative;
    cursor: pointer;
}

.container-testimonial .card .face{
    width: 400px;
    height: 250px;
    transition: 0.5s;

    @media screen and (max-width: 1200px) {
        width: 300px;
        height: 200px;
    }

    @media screen and (max-width: 768px) {
        width: 400px;
        height: 250px;
    }
}

.container-testimonial .card .face.face1{
    position: relative;
    background: #20242B;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    transform: translateY(150px);
    @media screen and (max-width: 1200px) {
        transform: translateY(100px);
    }

    @media screen and (max-width: 768px) {
        transform: translateY(150px);
    }
}

.container-testimonial .card:hover .face.face1{
    background: #3B97D3;
    transform: translateY(0);
}

.container-testimonial .card .face.face1 .content{
    /* opacity: 0.1; */
    transition: 0.5s;
}

.container-testimonial .card:hover .face.face1 .content{
    opacity: 1;
}

.container-testimonial .card .face.face1 .content img{
    max-width: 125px;
    margin-left: 60px;
    margin-bottom: 10px;

    @media screen and (max-width: 1200px) {
    max-width: 100px;
    margin-left: 50px;
    margin-bottom: 0px;
    }

    @media screen and (max-width: 768px) {
    margin-left: 60px;
    max-width: 125px;
    margin-bottom: 10px;
    }
}

.container-testimonial .card .face.face1 .content h3{
    margin: 10px 0 0;
    padding: 0;
    color: #fff;
    text-align: center;
    font-size: 1.5em;

    @media screen and (max-width: 1200px) {
    font-size: 1.25em;
    }

    @media screen and (max-width: 768px) {
    font-size: 1.5em;
    }
}

.container-testimonial .card .face.face2{
    position: relative;
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 0 20px 50px rgba(0, 0, 0, 0.8);
    transform: translateY(-100px);
}

.container-testimonial .card:hover .face.face2{
    transform: translateY(0);
}

.container-testimonial .card .face.face2 .content p{
    margin: 0;
    padding: 0;
    text-align: center;
    font-size: 22px;

    @media screen and (max-width: 1200px) {
        font-size: 17px;
    }

    @media screen and (max-width: 768px) {
        font-size: 22px;
    }
}

.container-testimonial .card .face.face2 .content a{
    margin: 15px 0 0;
    display:  inline-block;
    text-decoration: none;
    font-weight: 900;
    color: #333;
    padding: 5px;
    border: 1px solid #333;
}

.container-testimonial .card .face.face2 .content a:hover{
    background: #333;
    color: #fff;
}