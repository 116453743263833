.youtube-channel {
    box-sizing: border-box;
    padding: 150px 64px;
    max-width: 1500px;
    display: grid;
    grid-gap: 48px;
    /* grid-template-columns: repeat(auto-fill, minmax(410px, 1fr)); Adjust column widths */
    grid-template-columns: 1fr 1fr 1fr;
    
    justify-content: center;
    align-items: center;
    overflow:auto;
    margin: 0 auto;
    @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (max-width: 910px) {
        grid-template-columns: 1fr;
    }
    
}

  /* .extra-div {
    display: grid;
    justify-content: center;
    align-items: center;
    width: 100%;
  } */
  
  .youtube-video {
    /* padding-left: 100px; */
    
    grid-template-rows: repeat(auto-fill, minmax(410px, 1fr)); 
    justify-content: center;
    align-items: center;
    width: 100%;
  
    }
  
  .youtube-video a {
    text-decoration: none;
    color: inherit;
  }


  .card {
    width: 100%;
    height: 400px;
    background-color: #EFF2F7;
    border-radius: 0.75rem;
    cursor: pointer;
    border: 1px solid rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .card:hover {
    cursor: pointer;
  }
  
  .text {
    max-width: 480px;
    margin: 0 auto;
    overflow: hidden;
    border-radius: 0.75rem;
  }
  
  .tedi {
    width: 100%;
    display: block;
    transition-property: transform;
    transition-duration: 0.2s;
    
  }
  
  .tedi:hover {
    transform: scale(1.1);
  }
  
  .card-bottom {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    padding-top: 20px;
    padding-left: 15px;
  }
  
  .tag {
    font-weight: bold;
    color: black;
    font-size: 20px;
    text-align: left;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 1rem;
  }
  
  .tedi1 {
    width: 45px;
    height: 45px;
    border-radius: 100%;
  }
  
  .text1 {
    font-weight: bold;
    color: black;
    font-size: 1.125rem;
  }
  
  .duration {
    display: flex;
    flex-direction: row;
    float: right;
    right: 16px;
    bottom: 0;
    position: absolute;
  }
  
  .duration h3 {
    margin-right: 10px;
  }
  
  .metrix {
    color: #3b97d3;
  }
  
  .icon {
    font-size: 50px;
    margin-top: -17px;
  }
  
 .filterButton {
  border: 1px solid rgba(0, 0, 0, 0.25);
  font-size: 1rem; /* You may need to adjust the font size to match Tailwind's text-md */
  padding-left: 0.75rem;
  padding-right: 0.75rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-radius: 0.75rem;
  background-color: #F5F5F5;
  
}

.filterButton:focus{
  color: #fff;
}

.filterButton:focus {
  background-color: #4B91D6;
}
    
.grid-button{
  /* display: grid; */
  /* gap: 50px; */
  /* grid-template-rows: repeat(auto-fill, minmax(410px, 1fr)); 
  justify-content: center;
  align-items: center; */ 
} 
    
    
    