/* General styles for the section */
.general {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 160px 0;
    background: #eff2f7;
  }
  
  /* container1 styles */
  .container-old {
    width: 100%;
    box-sizing: border-box;
    padding: 0 64px;
    max-width: 1500px;
    
    @media screen and (max-width: 1024px) {
      padding: 0 48px;
    }
  
    /* title styles */
    .title-main {
      text-align: center;
      user-select: none;
      margin-bottom: 64px;
  
      /* h1 {
        font-weight: 900;
        font-size: 24px;
        color: #3b97d3;
      } */
  
      /* p {
        max-width: 700px;
        text-align: center;
        margin: 0 auto;
        font-size: 22px;
        font-weight: 500;
        line-height: 32px;
      } */
    }

    .h1 {
        font-weight: 900;
        font-size: 44px;
        color: #3b97d3;
      }
  
    .p {
      max-width: 700px;
      text-align: center;
      margin: 0 auto;
      font-size: 22px;
      font-weight: 500;
      line-height: 32px;
    }
  
    /* Services container1 styles */
    .services1 {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 32px;

      @media screen and (max-width: 1200px) {
        grid-template-columns: 1fr 1fr;
      }
  
      @media screen and (max-width: 910px) {
        grid-template-columns: 1fr;
      }
  
      /* Individual service item styles */
      .service1 {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        
        text-decoration: none;
        color: inherit;
        border-radius: 5px;
        padding: 32px;
        user-select: none;
        cursor: pointer;
        transition: all 0.3s ease;
        background: white;
        border: 1px solid rgba(0,0,0,0.25);
  
        &:hover {
          border-color: #3b97d3;
          background: #3b97d3;
          color: white;
          transform: scale(1.05);
  
          /* Icon styles on hover */
          .icons {
            color: white;
          }
  
          .more {
            color: white;
            border: 1px solid white;
          }

          .date {
            color: white;
          }
        }

        
        /* to remove purple color generated from Link property */
        .link-style {
          text-decoration: none;
          color: inherit;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        }
  
        /* Icon styles */
        .icons {
          color: #3b97d3;
          font-size: 40px;
          transition: all 0.3s;
        }
  
        /* title1 styles */
        .title1 {
          font-weight: 900;
          font-size: 25px;
          margin: 16px 0;
        }
  
        /* Description styles */
        .shortdescription {
          margin-bottom: 20px;
          line-height: 26px;
          font-size: 17px;
        }

        .top {
          /* padding-bottom: 10px; */
        }

        .bottom {
          display: flex;
          justify-content: space-between;
          width: 100%;
        }

        .date {
          font-weight: 900;
          font-size: 20px;
          margin-top: 10px;
          color: #3b97d3;

          @media screen and (max-width: 530px) {
            font-size: 20px;
          }
        }
  
  
        /* "More" link styles */
        .more {
          color: #3b97d3;
          font-weight: 900;
          text-transform: uppercase;
          transition: all 0.3s ease;
          cursor: pointer;
          padding: 10px 13px;
          border-radius: 5px;
          border: 1px solid rgba(0, 0, 0, 0.25);
          background: none;

           @media screen and (max-width: 530px) {
            padding: 10px 10px;
          }
        }

        .more .arrow {
          transition: transform 0.3s ease;
        }
        
        .more:hover .arrow {
          transform: translateX(4px); /* Move the arrow 6px to the right on hover */
        }

      }
    }
  }
  