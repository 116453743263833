@import url('https://fonts.googleapis.com/css2?family=Heebo:wght@100;700;900&family=Rajdhani:wght@300;400;500;600;700&display=swap');

body {
    font-family: 'Rajdhani', sans-serif;
}

* {
    margin: 0;
    padding: 0;
}

::-webkit-scrollbar {
    width: 5px;
}

::-webkit-scrollbar-thumb {
    background: #3b97d3;
}

.TrengoWidgetLauncher__iframe {
    z-index: 999 !important;
}


.swal2-styled.swal2-confirm {
    background-color: #3b97d3 !important;
}

.swal2-styled.swal2-confirm:focus {
    box-shadow: 0 0 0 3px rgb(59 151 211 / 50%) !important;
}

.swal2-icon.swal2-warning {
    color: #235a7e !important;
    border-color: #235a7e !important;
}

.swal2-icon.swal2-success {
    color: #3acc8e !important;
    border-color: #3acc8e !important;
}

.swal2-icon.swal2-success [class^=swal2-success-line] {
    background-color: #3acc8e !important;
}

.swal2-icon.swal2-success .swal2-success-ring {
    border-color: #3acc8e !important;
}

::-webkit-scrollbar-track {
    background: #20242b;
}

::selection {
    background: #20242b;
    color: white;
}

.juicer-feed {
    max-height: 1024px;
    padding-right: 16px;
}

.juicer-feed h1.referral {
    display: none !important;
}

.juicer-feed.modern .j-poster {
    display: none;
}
